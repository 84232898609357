import { KeyValue } from "../../../app/globalTypes";

type NewRelicGlobals = KeyValue;

export const extractLicenceKey = (globals: NewRelicGlobals): string => {
    if (typeof globals.NEW_RELIC_LICENSE_KEY !== 'string') {
        throw new Error('NEW_RELIC_LICENSE_KEY not present or not string')
    }

    return globals.NEW_RELIC_LICENSE_KEY;
}

export const extractApplicationID = (globals: NewRelicGlobals): string => {
    if (typeof globals.NEW_RELIC_APPLICATION_ID !== 'string') {
        throw new Error('NEW_RELIC_APPLICATION_ID not present or not string')
    }

    return globals.NEW_RELIC_APPLICATION_ID;
}
