import { KeyValue } from "../../../app/globalTypes"
import { extractApplicationID, extractLicenceKey } from "./globals"

interface NewRelicArgs {
    licenseKey: string
    applicationID: string
}

interface NewRelicOptions {
    info: KeyValue
    init: object
    loader_config: object
}

// Populate using values in copy-paste JavaScript snippet.
export const options = (nro: NewRelicArgs): NewRelicOptions => ({
    init: {
        distributed_tracing: {
            enabled: true
        },
        privacy: {
            cookies_enabled: true
        },
        ajax: {
            deny_list: ["bam.nr-data.net"]
        }
    }, // NREUM.init
    info: {
        beacon: "bam.nr-data.net",
        errorBeacon: "bam.nr-data.net",
        sa: 1,
        licenseKey: nro.licenseKey,
        applicationID: nro.applicationID,
    }, // NREUM.info
    loader_config: {} // NREUM.loader_config
})

declare const newRelicGlobals: KeyValue;

export const optionsFromGlobals = function (): NewRelicOptions {
    const licenseKey = extractLicenceKey(newRelicGlobals)
    const applicationID = extractApplicationID(newRelicGlobals)
    return options({ licenseKey, applicationID })
}
